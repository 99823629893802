import { CreateGroupDto } from './../../models/api/models/CreateGroupDto';
import { DialogController } from 'aurelia-dialog';
import { autoinject, containerless } from 'aurelia-framework';
import { AddGroupMemberDto, UserSubscription, UserUserGroup } from 'models/api';
import { ErrorService } from 'services/error-service';
import { NotificationsService } from 'services/notifications-service';
import ProductService from 'services/product-service';
import SubscriptionService from 'services/subscription-service';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import UserService from 'services/user-service';

@containerless
@autoinject
export class GroupAddMemberModal {
  group: UserUserGroup;

  dto: any;

  alertMessage: string;

  validationController: ValidationController;
  validationRules: any;
  loading: boolean = false;

  constructor(
    private subscriptionService: SubscriptionService,
    private productService: ProductService,
    private dialogController: DialogController,
    private errorService: ErrorService,
    private notificationsService: NotificationsService,
    private validationControllerFactory: ValidationControllerFactory,
    private userService: UserService
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  activate(params) {
    this.dto = {
      email: null,
    };
    this.group = params.group;

    this.setupValidation();
  }

  async setupValidation() {
    ValidationRules.ensure((it: any) => it.email)
      .required()
      .withMessage('E-post er påkrevd')
      .email()
      .withMessage('Ugyldig e-postadresse')

      .on(this.dto);
  }

  async addMember() {
    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    this.loading = true;

    try {
      const dto: AddGroupMemberDto = {
        email: this.dto.email,
      };

      await this.userService.addGroupMember(this.group.id, dto);
      this.loading = false;
      this.notificationsService.success(
        'Invitasjon sendt',
        'En invitasjon har blitt sendt på e-post'
      );
      this.dialogController.ok();
    } catch (error) {
      this.loading = false;

      if (error.status === 400) {
        this.alertMessage = error.response?.data?.message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  cancel() {
    this.dialogController.cancel();
  }
}
