import {
  NavigationInstruction,
  Next,
  PipelineStep,
  Redirect,
  Router,
  RouterConfiguration,
} from 'aurelia-router';
import { AppRoutes } from 'routes';

export class App {
  public router: Router;

  public configureRouter(config: RouterConfiguration, router: Router) {
    config.title = 'Min aboID';
    config.options.pushState = true;
    // config.options.root = '/';

    config.addAuthorizeStep(AuthorizeStep);
    config.mapUnknownRoutes({ redirect: 'login' });

    config.map(AppRoutes);

    this.router = router;
  }
}

class AuthorizeStep implements PipelineStep {
  public async run(
    navigationInstruction: NavigationInstruction,
    next: Next
  ): Promise<any> {
    if (
      navigationInstruction
        .getAllInstructions()
        .some((i) => !i.config.settings.public)
    ) {
      const isLoggedIn = localStorage.getItem('aboIdUser:loggedIn');
      if (!isLoggedIn) {
        const loginRedirect =
          navigationInstruction.getAllInstructions()[0].fragment;
        if (loginRedirect) {
          localStorage.setItem('loginRedirect', loginRedirect);

          if (
            loginRedirect.startsWith('/login-client/') ||
            loginRedirect.startsWith('/subscribe/')
          ) {
            const clientSlug = loginRedirect.split('/')[2];
            if (clientSlug) {
              const returnUrl =
                navigationInstruction.getAllInstructions()[0]?.queryParams
                  ?.returnUrl;

              if (returnUrl) {
                localStorage.setItem('loginClientReturnUrl', returnUrl);
              }

              const productId =
                navigationInstruction.getAllInstructions()[0]?.queryParams
                  ?.productId;

              if (productId) {
                const newRedirect = `${loginRedirect}?productId=${productId}`;
                localStorage.setItem('loginRedirect', newRedirect);
              }

              let redirectUrl = `login?loginRedirect=${loginRedirect}&clientSlug=${clientSlug}`;

              const email =
                navigationInstruction.getAllInstructions()[0]?.queryParams
                  ?.email;
              if (email) {
                redirectUrl += `&email=${email}`;
              }

              return next.cancel(new Redirect(redirectUrl));
            }
          }

          return next.cancel(
            new Redirect(`login?loginRedirect=${loginRedirect}`)
          );
        }
        return next.cancel(new Redirect('login'));
      }
    }

    return next();
  }
}
