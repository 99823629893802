import { DialogController } from 'aurelia-dialog';
import { autoinject, containerless } from 'aurelia-framework';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import { CreateGroupDto, UpdateUserEmailDto } from 'models/api';
import { ErrorService } from 'services/error-service';
import { NotificationsService } from 'services/notifications-service';
import UserService from 'services/user-service';

@containerless
@autoinject
export class ConfirmEmailModal {
  dto: any;
  oldEmail: string;
  newEmail: string;

  alertMessage: string;
  maxMembers: number;

  validationController: ValidationController;
  validationRules: any;
  loading: boolean = false;

  constructor(
    private dialogController: DialogController,
    private errorService: ErrorService,
    private notificationsService: NotificationsService,
    private validationControllerFactory: ValidationControllerFactory,
    private userService: UserService
  ) {
    this.validationController =
      validationControllerFactory.createForCurrentScope();
  }

  activate(params) {
    this.dto = {
      emailVerifyCode: null,
    };

    this.oldEmail = params?.oldEmail;
    this.newEmail = params?.newEmail;

    this.setupValidation();
  }

  async setupValidation() {
    ValidationRules.ensure((it: any) => it.emailVerifyCode)
      .required()
      .withMessage('Kode er påkrevd')
      .minLength(6)
      .withMessage('Koden er ugyldig')
      .maxLength(6)
      .withMessage('Koden er ugyldig')

      .on(this.dto);
  }

  async updateEmail() {
    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    this.loading = true;

    try {
      const dto: UpdateUserEmailDto = {
        email: this.newEmail,
        emailVerifyCode: this.dto.emailVerifyCode,
      };

      await this.userService.updateEmail(dto.email, dto.emailVerifyCode);
      this.loading = false;
      this.dialogController.ok();
    } catch (error) {
      this.errorService.handleError(error);
      this.loading = false;
    }
  }

  cancel() {
    this.dialogController.cancel();
  }
}
