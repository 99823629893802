import { DialogController } from 'aurelia-dialog';
import { autoinject, containerless } from 'aurelia-framework';
import { ChangeProductPreviewRes, Product } from 'models/api';
import { ErrorService } from 'services/error-service';
import { NotificationsService } from 'services/notifications-service';
import ProductService from 'services/product-service';
import SubscriptionService from 'services/subscription-service';

@containerless
@autoinject
export class SubscriptionChangeProductModal {
  currentProductId: number;
  clientSlug: string;
  subscriptionId: number;
  products: Product[];

  preview: ChangeProductPreviewRes;
  selectedProduct: Product;

  constructor(
    private subscriptionService: SubscriptionService,
    private productService: ProductService,
    private dialogController: DialogController,
    private errorService: ErrorService,
    private notificationsService: NotificationsService
  ) {}

  activate(params: any) {
    this.currentProductId = params.currentProductId;
    this.clientSlug = params.clientSlug;
    this.subscriptionId = params.subscriptionId;
    this.getProducts();
  }

  async getProducts() {
    this.products = await this.productService.getPublicRelatedProducts(
      this.clientSlug,
      this.currentProductId
    );
  }

  cancel() {
    this.dialogController.cancel();
  }

  async selectProduct(product: Product) {
    try {
      this.selectedProduct = product;
      this.preview = await this.subscriptionService.changeProductPreview(
        this.subscriptionId,
        product.id
      );
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async changeProduct(product: Product) {
    try {
      await this.subscriptionService.changeProduct(this.subscriptionId, product.id);
      this.notificationsService.success(
        'Abonnement endret',
        `Ditt abonnement er nå endret til ${product.name}`
      );
      this.dialogController.ok();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
