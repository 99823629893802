import { autoinject, containerless, bindable } from 'aurelia-framework';
import { NotificationData } from './notifications-area';

@containerless
@autoinject
export class Notification {
  @bindable notificationData: NotificationData;

  constructor() {}
}
