import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

export interface ConfirmDangerModalOptions {
  title: string;
  body: string;
  confirmButtonText: string;
  cancelButtonText?: string;
}
@autoinject
export class ConfirmDangerModal {
  options: ConfirmDangerModalOptions;

  constructor(private dialogController: DialogController) {}

  activate(options: ConfirmDangerModalOptions) {
    this.options = options;
  }

  cancel() {
    this.dialogController.cancel();
  }

  confirm() {
    this.dialogController.ok();
  }
}
