import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router, NavigationInstruction } from 'aurelia-router';
import { autoinject, containerless } from 'aurelia-framework';
import { Animations, AnimationsService } from 'services/animations-service';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import environment from '../../../config/environment.json';
import { ProfileRes } from 'models/api';
import UserService from 'services/user-service';

@containerless
@autoinject
export class MainHeader {
  menuVisible: boolean = false;
  menuEl: HTMLDivElement;
  mobileMenuEl: HTMLDivElement;

  profile: ProfileRes;

  currentRoute: string;

  routerSubscription: Subscription;

  constructor(
    private animationsService: AnimationsService,
    private router: Router,
    private userService: UserService,
    private eventAggregator: EventAggregator
  ) {}

  activate() {
    this.getData();
  }

  attached() {
    this.currentRoute = this.router.currentInstruction?.config.name;
    this.routerSubscription = this.eventAggregator.subscribe(
      'router:navigation:complete',
      ({ instruction }: { instruction: NavigationInstruction }) => {
        this.currentRoute = instruction.config?.name;
      }
    );
  }

  detached() {
    this.routerSubscription.dispose();
  }

  async getData() {
    this.profile = await this.userService.getUser();

    // Navigate to terms page
    if (!this.profile.acceptedTerms) {
      this.router.navigateToRoute('accept-terms');
    }
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
    this.animationsService.animate(
      this.menuEl,
      this.menuVisible,
      Animations.FADE_SCALE_BLOCK
    );
    this.animationsService.animate(
      this.mobileMenuEl,
      this.menuVisible,
      Animations.FADE_SCALE_BLOCK
    );
  }

  async logout() {
    try {
      const res = await axios.post(
        `${environment.apiBaseUrl}/auth/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      if (res.status === 201) {
        localStorage.removeItem('aboIdUser:loggedIn');
        return this.router.navigateToRoute('login');
      }
    } catch (err) {
      console.error(err);
    }
  }
}
