import { PLATFORM } from 'aurelia-pal';
import { FrameworkConfiguration } from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    PLATFORM.moduleName('./aboid-spinner/aboid-spinner'),
    PLATFORM.moduleName('./activity-indicator/activity-indicator'),
    PLATFORM.moduleName('./heroicon/heroicon'),
    PLATFORM.moduleName('./aid-toggle/aid-toggle'),
  ]);
}
