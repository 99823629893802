import { inject } from 'aurelia-dependency-injection';
import {
  autoinject,
  containerless,
  bindable,
  customElement,
} from 'aurelia-framework';
import { TaskQueue } from 'aurelia-framework';

@autoinject
@containerless
export class AidToggle {
  constructor(
    private readonly element: Element,
    private taskQueue: TaskQueue
  ) {}

  @bindable({
    defaultBindingMode: 'twoWay',
  })
  checked: boolean;
  @bindable default: boolean = false;

  attached() {
    if (this.checked === undefined || this.checked === null) {
      this.checked = !!this.default;
    }
  }

  toggle() {
    this.checked = !this.checked;

    const changeEvent = new CustomEvent('change', {
      detail: {
        value: this.checked,
      },
      bubbles: true,
    });

    this.taskQueue.queueMicroTask(() => {
      this.element.dispatchEvent(changeEvent);
    });
  }
}
