import { autoinject, containerless, bindable } from 'aurelia-framework';
import { NotificationsService } from 'services/notifications-service';
import { NotificationData } from './notifications-area';

@containerless
@autoinject
export class NotificationSuccess {
  @bindable notificationData: NotificationData;

  constructor(private notificationsService: NotificationsService) {}

  close() {
    this.notificationsService.close(this.notificationData.id);
  }
}
