import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, containerless } from 'aurelia-framework';
import { nanoid } from 'nanoid';

export enum NotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface NotificationData {
  id?: string;
  notificationType: NotificationTypes;
  title: string;
  message: string;
  autoHide?: number;
  closeButton?: boolean;
}

@containerless
@autoinject
export class NotificationsArea {
  notifications: NotificationData[] = [];

  protected notificationsSubscription: Subscription;
  protected closeNotificationsSubscription: Subscription;

  constructor(private eventAggregator: EventAggregator) {}

  attached() {
    this.notificationsSubscription = this.eventAggregator.subscribe(
      'notifications:create',
      (notificationData: NotificationData) => {
        this.createNotification(notificationData);
      }
    );

    this.closeNotificationsSubscription = this.eventAggregator.subscribe(
      'notifications:close',
      (notificationId: string) => {
        this.closeNotification(notificationId);
      }
    );
  }

  detached() {
    this.notificationsSubscription.dispose();
    this.closeNotificationsSubscription.dispose();
  }

  createNotification(data: NotificationData) {
    const notification = {
      id: nanoid(),
      ...data,
    };

    this.notifications.push(notification);

    setTimeout(() => {
      this.enterTransition(notification.id);
    });

    // Autohide
    if (notification.autoHide) {
      setTimeout(() => this.closeNotification(notification.id), notification.autoHide);
    }
  }

  closeNotification(id: string) {
    setTimeout(() => this.leaveTransition(id));
    setTimeout(() => {
      this.notifications.splice(
        this.notifications.findIndex((i) => i.id === id),
        1
      );
    }, 250);
  }

  enterTransition(notificationId: string) {
    const item = document.querySelector(`div[data-notification-id="${notificationId}"]`);
    item?.classList.add('transform', 'ease-out', 'duration-300', 'transition');
    item?.classList.add('translate-y-0', 'opacity-100', 'sm:translate-X-0');
  }

  leaveTransition(notificationId: string) {
    const item = document.querySelector(`div[data-notification-id="${notificationId}"]`);
    item?.classList.remove('ease-out', 'duration-300');
    item?.classList.add('ease-in');
    item?.classList.remove('opacity-100');
  }
}
