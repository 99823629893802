import { DialogController } from 'aurelia-dialog';
import { autoinject, containerless } from 'aurelia-framework';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import { CreateGroupDto, UserSubscription, UserUserGroup } from 'models/api';
import { ErrorService } from 'services/error-service';
import { NotificationsService } from 'services/notifications-service';
import UserService from 'services/user-service';

@containerless
@autoinject
export class CreateGroupModal {
  subscriptions: UserSubscription[];
  groups: UserUserGroup[];

  dto: any;

  alertMessage: string;
  maxMembers: number;

  validationController: ValidationController;
  validationRules: any;
  loading: boolean = false;

  constructor(
    private dialogController: DialogController,
    private errorService: ErrorService,
    private notificationsService: NotificationsService,
    private validationControllerFactory: ValidationControllerFactory,
    private userService: UserService
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  activate(params) {
    this.dto = {
      name: null,
      selectedSubscriptionId: null,
    };
    this.subscriptions = params.subscriptions;
    this.groups = params.groups;

    this.setupValidation();
  }

  async setupValidation() {
    ValidationRules.ensure((it: any) => it.name)
      .required()
      .withMessage('Gruppenavn er påkrevd')

      .ensure((it: any) => it.selectedSubscriptionId)
      .required()
      .withMessage('Abonnement er påkrevd')

      .on(this.dto);
  }

  selectSubscription() {
    this.alertMessage = null;
    this.maxMembers = null;

    // Check if subscription is shareable
    const subscription = this.subscriptions.find((it) => it.id === this.dto.selectedSubscriptionId);
    if (!subscription.product.groupEnabled) {
      this.alertMessage = 'Dette abonnementet kan ikke deles med andre.';
      return;
    }

    // Check if subscription is already shared
    const existingGroup = this.groups.find(
      (it) => it.subscription.id === this.dto.selectedSubscriptionId
    );
    if (existingGroup) {
      this.alertMessage =
        'Du har allerede en gruppe med dette abonnementet. Du kan bare lage en gruppe for hvert abonnement.';
      return;
    }

    // Check if subscription is invoiced by members
    if (subscription.product.groupInvoiceByMembers) {
      this.alertMessage = 'Dette abonnementet faktureres basert på antall medlemmer i gruppen.';
    }

    this.maxMembers = subscription.product.groupMaxMembers;
  }

  async createGroup() {
    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    this.loading = true;

    try {
      const dto: CreateGroupDto = {
        name: this.dto.name,
        subscriptionId: this.dto.selectedSubscriptionId,
      };

      const groupRes = await this.userService.createGroup(dto);
      this.loading = false;
      this.dialogController.ok(groupRes.id);
    } catch (error) {
      this.errorService.handleError(error);
      this.loading = false;
    }
  }

  cancel() {
    this.dialogController.cancel();
    // this.dialogController.ok();
  }
}
